html {
  background-color: #ffffff;
  box-sizing: border-box;
}

body {
  font-family: "Raleway", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1149px;
}
@media only screen and (max-width: 1366px){
  .container {
    padding-left: 25px!important;
    padding-right: 25px!important;
  }
}

@media (min-width: 1300px){
  .container {
    min-width: 1300px;
  }
}

/* Banner */
.banner{
  background: #FFFFFF;
  box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 10px 10px;
  display: grid;
  gap: 30px;
  align-items: center; 
  grid-template-columns: 0.8fr 2fr 0.7fr;
  position: relative;
  z-index: 3;
}
.banner .logo-box{
  background: #EAF2F8;
  border-radius: 10px;
}
.banner .logo-box a{
  background: url(../image/domainer_logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  display: block;
  font-size: 0;
  padding: 20px 30px;
  height: 138px;
}
.banner .title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 40px;
  color: #090909;
}
.banner .info{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7F82;
  position: relative;
  margin-left: 15px;
}
.banner .info:before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 100%;
  margin-left: -15px;
  border-radius: 10px;
  background: #F27558;
}
.banner .domaner-btn{
  background: #F27558;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  padding: 23px 5px;
  display: block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
.banner .domaner-btn:hover {
  background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
  .banner .title{
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 10px;
  }
  .banner .info{
      font-size: 18px;
  }
  .banner .domaner-btn{
      font-size: 18px;
  }
  .banner .domaner-btn {
      font-size: 18px;
      line-height: 22px;
      display: table;
      margin: 0 auto;
      padding: 23px 30px;
  }
}
@media only screen and (max-width: 940px) {
  .banner{
      grid-template-columns: 1fr;
      padding: 10px;
  }
  .banner .logo-box a{
      background: url(../image/domainer_logo_mob.svg);
      background-repeat: no-repeat;
      background-position: center center;
      height: 66px;
  }
  .banner .title{
      line-height: 24px;
      text-align: center;
  }
  .banner .info{
      font-size: 16px;
      line-height: 20px;
      text-align: center;
  }
  .banner .info:before{
      content: none;
  }
}
@media only screen and (max-width: 440px) {
  .banner .domaner-btn {
      display: block;
      padding: 23px 0px;
  }
}

h1, h2, h3 {
  font-family: "Poppins";
}

h1 {
  font-style: normal;
  font-weight: 275;
  font-size: 52px;
  line-height: 136%;
  color: #fff;
  margin-bottom: 40px;
  margin-top: 60px;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
  }
}

h1 .thin {
  font-weight: 300;
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  margin-bottom: 25px;
}

h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  color:#403D51;
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

p {
  font-size: 17px;
  line-height: 30px;
  color: #403D51;
}

p:not(:last-child) {
  margin-bottom: 30px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.xtra-bold {
  font-weight: 600;
}

.site-logo {
  position: relative;
  z-index: 3;
  padding: 30px 0;
  color: white;
}

.site-logo .main-logo, .footer .main-logo {
  position: relative;
  display: inline-block;
  width: 400px;
  height: 43px;
  vertical-align: middle;
  font-size: 0;
  background: url(../image/logo.svg) no-repeat 0 0 / contain;
}

.logo-wrapper {
  padding-top: 25px;
  padding-bottom: 22px;
  position: relative;
  z-index: 2;
}

.site-header { 
  background-color: #170E40;
  padding-bottom: 325px;
}

.site-header-text {
  position: relative;
  z-index: 2;
}


.header-description {
  color: #E9E6F6;
}

.site-header-inner {
  display: grid;
  grid-template-columns: 1fr 35%;
}

.container {
  position: relative;
}
.container.hero::before {
  position: absolute;
  content: "";
  background: url(../image/hero-bg.webp);
  background-repeat: no-repeat;
  width: 1641px;
  height: 1167px;
  background-size: contain;
  top: -200px;
  right: -981px;
}

.header-image {
  position: relative;
}

.header-image::before {
  position: absolute;
  content: "";
  background: url(../image/hero-img-min.webp);
  background-repeat: no-repeat;
  width: 530px;
  height: 552px;
  background-size: contain;
  top: -37px;
  right: -150px;
}

.body-wrapper {
  overflow: hidden;
}

/* ENDS HERO  */



.what-is-metaverse {
  margin-top: -275px;
  padding: 60px 90px;
  background-color: #fff;
  box-shadow: 0px -87px 69px 0px #251C454D;
  border-radius: 3px;
}

#what-is {
  margin-left: 80px;
  position: relative;
}

#what-is::before {
  position: absolute;
  content: "";
  background-image: url(../image/AR-min.webp);
  width: 68px;
  height: 68px;
  background-size: contain;
  left: -80px;
  top: -10px;
}

.nav-wrapper {
  margin-top: 69px;
}

.nav-wrapper a:hover {
  background-size: 150%;
  transition: background-image 1s;
}

.nav-wrapper span:hover {
  background-size: 120%;
  transition: background-image 1s;
}

.what-is-ul li {
  margin-bottom: 30px;
  font-weight: 600;
  color: #403D51;
  position: relative;
  margin-left: 25px;
}

.what-is-ul li:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 10px;
  top: 7px;
  left: -15px;
}

b {
  font-weight: 800;
}

.how-it-works {
  padding: 50px 90px;
  position: relative;
  z-index: 2;
}

.how-it-works::before {
  position: absolute;
  content: "";
  background-color: #EBE5FF;
  width: 222px;
  height: 100%;
  z-index: -1;
  top: 0px;
    left: 0px;
}

/* CARDS  */

.card {
  display: none;
}

.cardsWrapper{
  margin-top: 50px;
}

.card.active {
  display: grid;
    grid-template-columns: 90% 1fr;
}

.card-bg {
  padding: 50px;
  background-color: #FFD2B7;
  margin-right: 20px;
  display: grid;
  grid-template-columns: 1fr 82%;
  min-height: 331px;
  align-items: center;
}

.card-bg.purple {
  background-color: #EBE5FF;
}

.nextWapper {
  position: absolute;
  right: -30px;
  bottom: 12%;
  width: 215px;
}
@media only screen and (max-width: 1024px) and (min-width: 967px) {
  .nextWapper {
    right: -38px;
  }
  .card-bg{
    gap: 30px;
  }
  .card-image {
    margin-left: 0px!important;
  }
}
@media only screen and (max-width: 966px) and (min-width: 700px) {
  .nextWapper {
    right: -52px;
  }
  .card-bg{
    gap: 30px;
    padding: 30px 30px 30px 50px;
  }
  .card-image {
    margin-left: 0px!important;
  }
  .card-content{
    padding-right: 50px;
  }
}
.next-list{
  display: flex;
  position: relative;
}
.next-list::before{
  content: "";
  position: relative;
  top: -70px;
  left: 20px;
  background-position: top;
  width: 77px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../image/arrow.svg)
}
.next-list li {
  opacity: 0;
  display: none;
} 
.next-list li.active+li{
  opacity: 1;
  height: auto;
  display: block;
  position: relative;
  cursor: pointer;
}
.next-list li a{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #9A7BFF;
}
.next-list li.active+li::before{
  content: "Next Artist:";
  position: absolute;
  top: -30px;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  text-transform: uppercase;
  color: #403D51;
}
.next {
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.next-artist {
  margin-bottom: -5px !important;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.artist {
  font-size: 20px;
  color: #9A7BFF;
}

.next svg {
  position: relative;
  left: -60px;
}

.card-image {
  margin-left: 20px;
}

.card-image.zero {
  margin-left: 0;
}

.slider-nav {
  display: flex;
  justify-content: center;
  width: 90%;
}

.slider-nav li {
  background: #e0e0e0;
  border-radius: 3px;
  width: 14px;
  height: 14px;
  margin: 20px 0 20px 20px;
  cursor: pointer;
}

.slider-nav li.active {
  background: #9A7BFF;
}


/* ENDS CARDS  */


.attending {
  text-align: center;
  z-index: 2;
  position: relative;
  margin-top: 50px;
}

.attending:before {
  position: absolute;
  content: "";
  background-image: url(../image/hero-bg.webp);
  filter: blur(146px);
  transform: rotate(-180deg);
  z-index: -1;
  opacity: 0.25;
  top: -17px;
  left: 241px;
  width: 1000px;
  height: 572px;
}

.future {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 100px;
  padding-bottom: 100px;
}

.future-img {
  background-image: url(../image/dance-min.webp);
  background-repeat: no-repeat;

}


/* FAQ  */

.faq-wrapper h2 {
  color: #fff;
  text-align: center;
}

.white {
  color: #ffff;
  text-align: center;
}

.faq {
  margin-top: 35px;
  position: relative;
  margin-bottom: 35px;
  margin-left: 50px;
}

.faq:before {
  position: absolute;
  content: "";
  width: 15px;
  height: 100%;
  background-color: #2B1F61;
  border-radius: 3px;
  left: -50px;
}

.faq-title h3{
  margin-bottom: 10px;
  color: #fff !important;
  margin-left: 55px;
}
.faq-body a {
  text-decoration: underline;
  color: #D8D1FC;
  font-weight: 700;
}

.faq-body p {
  color: #D8D1FC;
}


.faq-wrapper {
  background-color: #170E40;
  padding-top: 100px;
  padding-bottom: 165px;
}

h3 {
  position: relative;
}


.faq:first-of-type h3:first-of-type:before{
  position: absolute;
  content: "";
  background-image: url(../image/singer.png);
  background-size: contain;
  width: 44px;
  height: 44px;
  top: -5px;
    margin-left: -55px;
}

.faq:nth-of-type(2) h3:first-of-type:before{
  position: absolute;
  content: "";
  background-image: url(../image/party.png);
  background-size: contain;
  width: 44px;
  height: 44px;
  top: -5px;
    margin-left: -55px;
}

.faq:nth-of-type(3) h3:first-of-type:before{
  position: absolute;
  content: "";
  background-image: url(../image/star.png);
  background-size: contain;
  width: 44px;
  height: 44px;
  top: -5px;
  margin-left: -55px;
}



.about-us {
  text-align: center;
  padding: 50px 90px;
    margin-top: -110px;
    background: #FFEEE3;
}

.nav-wrapper a {
  -webkit-transition: ease-out 0.4s;
  background: linear-gradient(93.5deg, #491DC8 -4.55%, #FF703A 90.05%);
  line-height: 27px;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-indent: 15px;
  line-height: 65px;
  border-radius: 3px;
  margin-bottom: 58px;
  position: relative;
  color: #fff;
  margin: 0 auto;
  width: 269px;
  cursor: pointer;
  display: flex;
}


footer {
  background-color: #0E0928;
}






@media only screen and (max-width: 768px) {
  .site-header-inner {
    padding: 26px 20px 28px;
  }
}

.header-image {
  position: relative;
  right: 0;
  bottom: 0;
}
@media only screen and (max-width: 768px) {
  .header-image {
    display: none;
  }
}

.header-description {
  margin-bottom: 60px;
}
@media only screen and (max-width: 768px) {
  .header-description {
    margin-bottom: 26px;
  }
}

.nav-wrapper p {
  margin-bottom: 54px;
  letter-spacing: 0.345em;
}
@media only screen and (max-width: 768px) {
  .nav-wrapper p {
    margin-bottom: 20px;
  }
}

.nav-wrapper ul {
  display: flex;
}
.nav-wrapper li {
  box-shadow: 0px 4px 33px 6px rgba(0, 0, 0, 0.1);
  width: 256px;
  height: 65px;
  border-radius: 50px;
  margin-right: 31px;
}
@media only screen and (max-width: 768px) {
  .nav-wrapper ul {
    display: block;
  }
  .nav-wrapper ul li:not(:last-child) {
    margin-bottom: 16px;
  }
  .nav-wrapper li {
    width: 100%;
  }
}

.nav-wrapper a {
  box-shadow: inset 0 0 0 0 #8dca65;
  -webkit-transition: ease-out 0.4s;
  background: linear-gradient(93.5deg, #491DC8 -4.55%, #FF703A 90.05%);
  box-shadow: 0px 4px 38px #08041C;
  line-height: 27px;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  -moz-transition: all ease-out 0.4s;
  transition: all ease-out 0.4s;
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-indent: 50px;
  line-height: 65px;
  border-radius: 3px;
  margin-bottom: 58px;
  position: relative;
  color: #fff;
  width: 269px;
  display: flex;
}





footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  footer .container {
    flex-direction: column;
    padding-bottom: 24px;
  }
}

footer .copyright {
  color: #828c98;
  font-size: 14px;
}

.m-visible {
  display: none;
}

.m-hidden {
  display: initial;
}

/* MOBILE */

@media only screen and (max-width: 768px) {
  
  .container,
  footer {
    padding: 0px 22px;
  }

  h1 {
    font-size: 30px;
    margin-top: 10px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

  .header-description {
    font-size: 18px;
  }
  .site-logo .main-logo{
    width: 100%;
    background-position: center;
  }
  .site-header-inner {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .header-description,
  h1 {
    text-align: center;
  }

  .nav-wrapper a {
    margin: 0 auto;
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
  }

  .what-is-metaverse {
    padding: 40px 35px;
  }

  .how-it-works {
    padding: 28px 15px;
  }

  .how-it-works::before {
    width: 92px;
  }

  #what-is::before {
    top: -17px;
  }

  .attending:before {
    left: -20px;
  }

  .future-img {
    width: 100%;
    height: 299px;
    background-size: contain;
    background-position: center;
  }

  .future {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding-bottom: 50px;
  }

  #future {
    margin-top: 25px;
  }

  .faq-wrapper {
    padding-top: 50px;
  }

  .faq {
    margin-left: 20px;
  }
  
  .faq:before {
    left: -30px;
  }

  .faq:nth-of-type(2) h3:first-of-type:before,
  .faq:nth-of-type(3) h3:first-of-type::before {
    top: 15px;
  }

  .about-us {
    padding: 40px 20px;
  }

  .nav-wrapper span {
    width: 100%;
    text-indent: 10px;
  }

  .nav-wrapper.about {
    margin-top: 40px;
  }

/* Slider */
.slider{
  display: flex;
  gap: 20px;
}
 
.card.active+.card{
  opacity: 0.6;
  display: block;
  height: auto;
}
.card-bg{
  min-height: 605px;
  grid-template-columns: 1fr;
  padding: 24px;
  margin: 0;
  max-width: 100%;
}
.card{
  flex-basis: 301px;
}
.card.active{
  grid-template-columns: 1fr;
}
.card-image{
  margin: 0 0 15px 0;
  display: flex;
  gap: 20px;
}
.card-image[data-name]::after {
	content: attr(data-name);
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #403D51;
  margin: auto;
}
.card-bg h3{
  display: none;
}
.nextWapper {
  position: relative;
  right: -30%;
  bottom: 0;
  margin-top: 15px;
}
.next-list{
  justify-content: center;
  flex-direction: row-reverse;
}
.next-list::before{
  top: 7px;
  width: 28px;
}
.next-list li.active+li::before{
  font-size: 15px;
  line-height: 30px;
  top: 0;
  left: -100px;
}
.next-list li a{
  font-size: 16px;
  line-height: 30px;
}

  /* read more  */

  .m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
}
.m-hidden, .mobile-detector {
    display: none;
}
.m-visible {
    display: flex;
}
.m-hidden {
    display: flex;
}
.m-slide-down-measure p {
    margin: 0!important;
    padding-top: 20px;
}
.m-readmore-btn{
    background: transparent;
    border: none;
    font-size: 18px;
    font-weight: 500;
    color: #9A7BFF;
    text-decoration: underline;
    width: 100%;
    cursor: pointer;
}

.m-slide-down p {
  margin-bottom: 30px !important;
}

.m-readmore-btn * {
    pointer-events: none;
}
.m-visible {
    display: flex;
}
.m-hidden, .mobile-detector {
    display: none;
}




  /* ENDS read more  */





  footer .logo-wrapper {
    padding-bottom: 8px;
  }
}


@media only screen and (max-width: 480px) {
  #what-is::before {
    top: 0;
  }
}


@media only screen and (max-width: 373px){
  .faq:first-of-type h3:first-of-type:before {
    top: 15px;
  }
}